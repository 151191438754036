<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-list-view" class="data-list-container">
    <vx-card
      ref="filterCard"
      v-bind:title="$t('Filters')"
      class="user-list-filters mb-8"
      collapse-action
    >
      <div class="vx-row">
        <!-- CountryID -->

        <!-- VideoGroupID -->

        <!-- VideoName -->
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("Keyword") }}</label>
          <vs-input
            v-model="VideoSearch.Description"
            class="w-full"
            name="Description"
          />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateFrom") }}</label>
          <datepicker
            placeholder="from"
            v-model="VideoSearch.DateFrom"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <label class="text-sm opacity-75">{{ $t("DateTo") }}</label>
          <datepicker
            placeholder="from"
            v-model="VideoSearch.DateTo"
            class="vx-col w-full"
            name="from"
          ></datepicker>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full mb-4">
          <vs-button
            class="my-6 rounded-sm"
            @click="VideoSearchFirst"
            color="warning"
            >{{ $t("Search") }}</vs-button
          >
        </div>

      </div>
    </vx-card>
    <div class="vx-row">
      <div
        class="vx-col w-full md:w-1/3 sm:w-1/2 mb-base"
        v-for="item in Videos"
        :key="item.ID"
        @click="$router.push(item.url).catch(() => {})"
      >
        <vx-card class="vxCardPatient text-center cursor-pointer rounded-xl">
          <div class="vx-row ml-3 mr-3" style="justify-content: right">
            <feather-icon
              icon="TrashIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click="openConfirm(item)"
            />
          </div>
          <div class="vx-col">
            <video
              :width="350"
              :height="200"
              id="videojs-sprite-thumbnails-player"
              class="video-js vjs-default-skin"
              data-setup='{"aspectRatio":"12:5"}'
              controls
            >
              <source :src="baseURL + item.URL" type="video/mp4" />
              <!-- <source src="//vjs.zencdn.net/v/oceans.webm" type='video/webm'> -->
            </video>

            <div class="vx-col mb-3 mt-3">
              <h4 class="mb-2">
                {{ item.AppUser.ApplicationUser.FullName }}
              </h4>

              <small @click="editData(item)"
                >{{ $t("MainData") }} </small
              >
            </div>
          </div>

          <div class="vx-row mt-3">
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
              >
                {{ $t("RateUsers") }}
                <star-rating
                  v-model="item.RateCount"
                  v-bind:star-size="10"
                  v-bind:show-rating="false"
                  v-bind:max-rating="10"
                  read-only="true"
                ></star-rating>
              </vs-button>
            </div>
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
              >
                {{ $t("LiksUsers") }}

                ({{ item.LikeCount }})
              </vs-button>
            </div>
          </div>
          <div class="vx-row mt-3">
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                >{{ $t("Comments") }} ({{ item.CommentCount }})</vs-button
              >
            </div>
            <div class="lg:w-1/2 w-full">
              <vs-button
                class="mr-2 customizer-btn rounded-lg text-sm"
                color="warning"
                style="width: 95%"
                type="filled"
                >{{ $t("ViewCount") }}  ({{item.ViewCount}})</vs-button
              >
            </div>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>
<script>
import moduleVideo from "@/store/Video/moduleVideo.js";
import { domain } from "@/gloabelConstant.js";
// import VueVideoThumbnail from 'vue-video-thumbnail'
import StarRating from "vue-star-rating";
 import Datepicker from "vuejs-datepicker";

export default {
  components: {
    StarRating,
    // VueVideoThumbnail
    Datepicker
  },

  data() {
    return {
      search: {
        IsPaging: true,
        PageNumber: 1,
        PageSize: 20,
      },
      selected: [],
      itemsPerPage: 10,
      isMounted: false,
      baseURL: domain,
      sidebarData: {},
      rowDataForDelete: {},
      activeConfirm: false,
    };
  },
  computed: {
    VideoSearch() {
      debugger;
      return this.$store.state.VideoList.VideoSearchObj;
    },
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    Videos() {
      return this.$store.state.VideoList.Videos;
    },
  },

  methods: {
    VideoDataSearch() {
      debugger;
      this.$store
        .dispatch("VideoList/SearchVideo", this.VideoSearch)
        .then((res) => {
          if (
            document.documentElement.scrollHeight <= window.innerHeight &&
            res.data.Data.length >= this.VideoSearch.PageSize
          ) {
            this.VideoSearch.PageNumber++;
            this.VideoDataSearch();
          }
        });
    },
    VideoSearchFirst() {
      debugger;
      this.VideoSearch.PageNumber = 1;
      this.VideoDataSearch();
    },
    addNewData() {
      // this.sidebarData = {};
      this.$router.push({ name: "Video-add/Edit" });
    },

    ShowVideoSurgeries(data) {
      this.$router.push({
        name: "VideoSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowPatientReservationSurgeries(data) {
      this.$router.push({
        name: "PatientResevationSurgeries",
        params: { ID: data.ID },
      });
    },
    ShowVideoSpecialties(data) {
      this.$router.push({
        name: "VideoSpecialities",
        params: { ID: data.ID },
      });
    },
    ShowVideoDoctors(data) {
      this.$router.push({
        name: "VideoDoctors",
        params: { ID: data.ID },
      });
    },

    deleteData(data) {
      debugger;
      this.$store.dispatch("VideoList/DeleteVideo", data).catch((err) => {
        console.error(err);
      });
    },

    editData(data) {
      this.$router.push({ name: "Video_Edit", params: { ID: data.ID } });
    },

    openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        accept: this.acceptAlert,
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
      });
    },

    acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully"),
      });
    },
  },
  destroyed() {
    window.onscroll = () => {};
  },
  created() {
    if (!moduleVideo.isRegistered) {
      this.$store.registerModule("VideoList", moduleVideo);
      moduleVideo.isRegistered = true;
    }

    this.$store.commit("VideoList/SET_Video", []);
    this.VideoSearchFirst();
    // this.$store.dispatch("VideoList/GetAllVideos");
  },
};
</script>

<style lang="scss">
#data-list-list-view small {
  color: rgb(255, 115, 0);
  text-decoration: underline rgb(255, 115, 0);
}

.vxCard:after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: 10%;
  z-index: 500000;
  width: 25%;
  height: 20%;
  border-top: 3px solid rgb(255, 115, 0) !important;
  border-bottom: 0 none rgba(255, 0, 0, 0.9) !important;
  border-right: 0 none rgba(255, 0, 0, 0.9) !important;
  border-left: 0 none rgba(0, 0, 0, 0.9) !important;
}
#data-list-list-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
